<template>
    <div style="width: 100%; position: relative">
        <!--            top-->
        <div style="width: 100%; height: 70px; position: absolute; box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19); z-index: 999999; background-color: #eeeeee; opacity: 0.8; display: flex; justify-content: space-between">
            <div style="height: 100%; font-size: 25px; font-weight: 600; display: flex; align-items: center; margin-left: 50px; color: #333">
                <img src="../assets/zhatulogo.png" height="39" style="margin-right: 15px"/>
            </div>
            <div style="height: 100%; display: flex; align-items: center; margin-right: 50px;">
                <img src="../assets/dp.png" style="width: 30px; cursor: pointer" title="官方网站" @click="openPage" />
            </div>
        </div>
        <full-page :options="options" ref="fullPage">
            <div class="section page1-wrap" >
                <div style="position: absolute; width: 100%; height: 40px; top: 70px; z-index: 2; display: flex">
                    <div style="z-index: 2; height: 100%; width: 10%; background-color: #f1f1f1; display: flex; align-items: center; flex-direction: row-reverse;">
                        <span style="margin-right: 10px">公告信息:
                            <img src="../assets/lb.png" height="15"/>
                        </span>
                    </div>
                    <div style="height: 100%; width: 80%; background-color: #f1f1f1; white-space: nowrap; display: flex; align-items: center; position: relative">
                        <div class="notice">
                            为贯彻落实省住房和城乡建设厅《关于开展城市建筑垃圾清运管理集中整治行动通知》的文件精神，加强我市城市建筑垃圾清运管理工作，进一步遏制公路扬尘污染，强化路面安全管控，严查渣土车、商砼车辆各类严重违法，全面清理路面安全隐患，市城市管理局深入开展“渣土车、商砼车”专项整治行动。公安、环保、住建、城管等部门不定时联合开展执法行动，加强对主要路段、施工工地等区域的巡查力度，严厉查处渣土车、商砼车运输等重点车辆“抛洒漏”、出厂带泥、车身未冲洗等扬尘污染抛洒等违法行为，采用错时巡查及“白加黑”的弹性工作方式突击开展治理行动，有效遏制违法运输车辆“钻空子”行为，渣土办大数据监管平台工作人员对渣土运输车及商砼车进行全天候监控，发现问题及时处理。下步，市城市管理局渣土办将继续对城区内的施工工地、运输车辆做好监督管理，持续保持对渣土运输车辆的严查高压态势，严格按照“三项准入”、“三不出场”制度，“四统一”规定，坚决做到违法行为不消除不放行，安全隐患不消除不放行，有效净化城区道路交通秩序，营造干净舒适的生活环境和整洁优美的道路通行环境。
                        </div>
                    </div>
                    <div style="z-index: 2; height: 100%; width: 10%; background-color: #f1f1f1"></div>
                </div>
                <div style="width: 100%; position: absolute; top: 0; bottom: 0">
                    <!--            content-->
                    <div style="width: 100%; height: calc(100%); background-repeat: no-repeat; background-size: cover" :style="{backgroundImage:'url('+require('../assets/backgroundimg.png')+')'}">
                        <div style="width: 100%; height: 70%; display: flex;">
                            <div style="height: 100%; width: 55%; display: flex; justify-content: center; align-items: center; color: #F3F3F3">
                                <div style="width: 600px; height: 600px; color: black" id="element">
<!--                                <div style="width: 600px; height: 600px; color: black" id="element" @mousemove="transformElement" @mouseleave="transformElementLeave">-->
                                    <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; position: relative; top: 50px;">
                                        <div style="margin-right: 15px; position: absolute; opacity: 0.1">
<!--                                            <img src="../assets/zhatulogo.png" height="520" style="position: relative; right: 140px"/>-->
                                        </div>
                                        <div style="position: relative; text-shadow: 1px 1px #fff; font-weight: 600">
                                            <div style="font-size: 40px">封 丘 县 智 慧 城 管 平 台</div>
                                            <div style="font-size: 27px; margin-left: 12px">Fengqiu smart City platform</div>
<!--                                            <div style="font-size: 40px">长 垣 市 智 慧 城 管 平 台</div>-->
<!--                                            <div style="font-size: 27px; margin-left: 12px">Changyuan smart City platform</div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="height: 100%; display: flex; align-items: center">
                                <!--                            hvr-grow-shadow hvr-underline-from-center-->
                                <img src="../assets/rightgif.gif" width="700px" style="position: relative; top: 50px" />
                            </div>
                        </div>
                        <div style="height: 30%; width: 100%">
                            <div style="display: flex; color: #F3F3F3; align-items: center; justify-content: center">
                                <div class="buttons" :style="{backgroundImage:'url('+require('../assets/928img1.png')+')'}" @click="tz('http://36.137.101.77:8088')">
                                    <div class="btn-jj">
                                        <div>渣土清运管理</div>
                                        <div>Slag Truck</div>
                                    </div>
                                    <div class="btn-detail">
                                        <div>
                                            <div style="margin-bottom: 30px; font-size: 20px;">
                                                <div>渣土清运管理</div>
                                                <div>slag truck</div>
                                            </div>
                                            渣土清运管理应行业痛点而生，聚焦工程运输车辆“两点一线”运输全过程，构建智能终端与监管平台结合的闭环管理系统，为安全、高效、绿色的城市建设保驾护航。
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons" :style="{backgroundImage:'url('+require('../assets/anquan.jpg')+')'}" @click="tz('http://aqjy.safeyes.cn')">
                                    <div class="btn-jj">
                                        <div>安全教育</div>
                                        <div>Safe Education</div>
                                    </div>
                                    <div class="btn-detail">
                                        <div>
                                            <div style="margin-bottom: 30px; font-size: 20px;">
                                                <div>安全教育</div>
                                                <div>Safe Education</div>
                                            </div>
                                            在整个道路交通安全工作中，交通安全宣传教育是一项长期的治本工作，是一个社会化的系统工程。
                                            随着我国经济建设的快速发展，道路交通安全形势日趋严峻，交通安全宣传教育工作的重要性和不可或缺性显而易见的
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="buttons" :style="{backgroundImage:'url('+require('../assets/928img3.png')+')'}" @click="tz('http://36.137.101.77:8088/808gps/login.html')">-->
<!--                                    <div class="btn-jj">-->
<!--                                        <div>环卫清扫管理</div>-->
<!--                                        <div>Sanitation Truck</div>-->
<!--                                    </div>-->
<!--                                    <div class="btn-detail">-->
<!--                                        <div>-->
<!--                                            <div style="margin-bottom: 30px; font-size: 20px;">-->
<!--                                                <div>环卫清扫管理</div>-->
<!--                                                <div>Sanitation Truck</div>-->
<!--                                            </div>-->
<!--                                            智慧环卫管理在产业规模跳跃式增长和国家政策方针指导的形势下，希望通过信息化、AI人工智能技术创新，帮助主管部门解决监管新课题。-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="buttons" :style="{backgroundImage:'url('+require('../assets/928img4.png')+')'}" @click="tz('http://61.54.110.124:9615/')">-->
<!--                                    <div class="btn-jj">-->
<!--                                        <div>餐油烟管理</div>-->
<!--                                        <div>Meal Lampblack</div>-->
<!--                                    </div>-->
<!--                                    <div class="btn-detail">-->
<!--                                        <div>-->
<!--                                            <div style="margin-bottom: 30px; font-size: 20px;">-->
<!--                                                <div>餐油烟管理</div>-->
<!--                                                <div>Meal Lampblack</div>-->
<!--                                            </div>-->
<!--                                            餐油烟管理系统以改善城市环境空气质量为目标，以降低餐饮业油烟排放为总要求。大力整治油烟直排，确保城市餐饮企业安装标准油烟净化设备，建立长效监管机制。-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section page4-wrap" style="background-color: aquamarine">
                <article-page></article-page>
            </div>
            <div class="section page2-wrap" style="display: flex; justify-content: center; align-items: center; background-color: #ebf4fe; padding-top: 70px">
                <img src="../assets/111.png" style="height: 100%"/></div>
            <div class="section page3-wrap" style="display: flex; justify-content: center; align-items: center; background-color: #006fa8; padding-top: 70px">
                <img src="../assets/222.png" style="height: 100%"/></div>
        </full-page>
    </div>
</template>

<script>
    import { ModelObj } from 'vue-3d-model';
    import articlePage from './Article'
    export default {
        name: "FrontPage",
        components: {
            articlePage,
            ModelObj
        },
        data() {
            return {
                options: {
                    licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
                    //是否显示导航，默认为false
                    navigation: true,
                    //为每个section设置背景色
                }

            }
        },
        methods: {

            tz(url) {
                window.open(url);
            },

            transformElementLeave() {
                let element = document.getElementById("element");
                element.style.transform = "rotateX(0) rotateY(0)";
            },

            transformElement(pp) {
                let element = document.getElementById("element");
                let x = pp.x;
                let y = pp.y;
                let box = element.getBoundingClientRect();
                let calcX = -(y - box.y - (box.height / 2)) / 10;
                let calcY = (x - box.x - (box.width / 2)) / 10;

                element.style.transform  = "rotateX("+ calcX +"deg) "
                    + "rotateY("+ calcY +"deg)";
            },
// 1234
            openPage() {
                window.open("http://hngps.cc")
            },
        }
    }
</script>

<style scoped>
    .btn-cars {
        font-size: 30px;
        font-weight: 600;
        margin-left: 5px;
        text-shadow: 2px 2px #000;
    }
    body {
        width: 100vw;
        height: 100vh;
        display: flex;
        transform-style: preserve-3d;
        perspective: 500px;
        cursor: pointer;
    }

    #element {
        margin: auto;
        transform-style: preserve-3d;
        transition: all .1s;
    }

    .buttons {
        height: 300px;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 35%;
        border-radius:  1px;
        background-repeat: no-repeat;
        background-size: cover;
        transition: ease-in-out 300ms;
        position: relative;
        border: 1px solid rgba(230, 230, 230, 1);
    }

    .buttons:hover {
        width: 37%;

    }

    .buttons:hover > .btn-jj {
        opacity: 0;
    }

    .btn-jj {
        position: absolute;
        left: 20px;
        bottom: 50px;
        text-shadow: 1px 1px #000;
        font-size: 20px;
        transition: ease-in-out 300ms;
    }

    .buttons:hover > .btn-detail {
        transition: ease-in-out 300ms;
        transition-delay: 0.2s;
        opacity: 1;
    }

    .btn-detail {
        opacity: 0;
        padding: 0 20px;
        text-shadow: 1px 1px #000;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .notice {
        animation-name: sss;
        animation-duration: 80s;
        animation-iteration-count: infinite;
        transition-timing-function: linear;
        position: absolute;
        left: -500px;
    }

    .notice:hover {
        animation-play-state: paused;
    }

    @keyframes sss {
        from {
            left: 300px;
        }

        to {
            left: -7400px;
        }
    }
</style>
