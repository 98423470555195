<template>
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; background-repeat: no-repeat; background-size: cover" :style="{backgroundImage:'url('+require('../assets/bj222.jpg')+')'}">
        <div style="width: 100%; height: 60%; display: flex; justify-content: center; color: #F3F3F3">

        </div>
        <div style="width: 100%; height: 37%; display: flex; justify-content: center; color: #F3F3F3">

            <el-divider direction="vertical" style="height: 37%"></el-divider>
            <div @mousemove="moveDh('b1')" :class="b1" class="div-big content">
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card overview">
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">封丘县城市管理局关于开展城市建筑垃圾清运管理集中整治行动的情况汇报</div>
                    </div>
<!--                </transition>-->
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card detail">
                        <div class="div-content">封丘县城市管理局关于开展城市建筑垃圾清运管理集中整治行动的情况汇报!</div>
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">为贯彻落实省住房和城乡建设厅《关于开展城市建筑垃圾清运管理集中整治行动通知》的文件精神，加强我市城市建筑垃圾清运管理工作，进一步遏制公路扬尘污染，强化路面安全管控，严查渣土车、商砼车辆各类严重违法，全面清理路面安全隐患，市城市管理局深入开展“渣土车、商砼车”专项整治行动。</div>
                        <el-button class="detail-btn" size="small" type="primary" @click="openDetail('http://fq.zhatuban.com')">查看详情</el-button>
                    </div>
<!--                </transition>-->
            </div>

            <el-divider direction="vertical" style="height: 37%"></el-divider>
            <div @mousemove="moveDh('b2')" :class="b2" class="div-big content">
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card overview">
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">市城市管理局开展“渣土车、商砼车”专项整治行动</div>
                    </div>
<!--                </transition>-->
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card detail">
                        <div class="div-content">市城市管理局开展“渣土车、商砼车”专项整治行动!</div>
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">为进一步遏制道路扬尘污染，强化路面安全管控，严查渣土车、商砼车辆各类严重违法，全面清理路面安全隐患，市城市管理局深入开展“渣土车、商砼车”专项整治行动。</div>
                        <el-button class="detail-btn" size="small" type="primary" @click="openDetail('http://fq.zhatuban.com')">查看详情</el-button>
                    </div>
<!--                </transition>-->
            </div>

            <el-divider direction="vertical" style="height: 37%"></el-divider>
            <div @mousemove="moveDh('b3')" :class="b3" class="div-big content">
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card overview">
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">市城市管理局召开党组扩大会议推进重点工作落实</div>
                    </div>
<!--                </transition>-->
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card detail">
                        <div class="div-content">市城市管理局召开党组扩大会议推进重点工作落实</div>
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">5月5日下午，市城市管理局召开党组扩大会议，局中层以上干部、市五家保洁公司及垃圾焚烧发电厂负责人参加了会议，会议由局长王国强主持。</div>
                        <el-button class="detail-btn" size="small" type="primary" @click="openDetail('http://fq.zhatuban.com')">查看详情</el-button>
                    </div>
<!--                </transition>-->
            </div>

            <el-divider direction="vertical" style="height: 37%"></el-divider>
            <div @mousemove="moveDh('b4')" :class="b4" class="div-big content">
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card overview">
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">市城管局加大巡查管控力度 提升市容秩序标准</div>
                    </div>
<!--                </transition>-->
<!--                <transition name="el-fade-in-linear">-->
                    <div class="div-card detail">
                        <div class="div-content">市城管局加大巡查管控力度 提升市容秩序标准</div>
                        <div class="div-date">2022年5月31日</div>
                        <div class="div-title">2月25日上午，市城市管理局局长王国强前往蒲东区检查市容秩序，重点查看了南关大三角周边环境卫生、万德隆商超门前三包落实情况。</div>
                        <el-button class="detail-btn" size="small" type="primary" @click="openDetail('http://fq.zhatuban.com')">查看详情</el-button>
                    </div>
<!--                </transition>-->
            </div>

            <el-divider direction="vertical" style="height: 37%"></el-divider>





        </div>
    </div>
</template>

<script>
    export default {
        name: "Article",
        data() {
            return {
                b1: '',
                b2: '',
                b3: '',
                b4: '',
            }
        },
        methods: {

            openDetail(url) {
                window.open(url);
            },

            moveDh(v) {
                // if (v === 'b1') {
                //     this.b1 = 'div-class';
                //     this.b2 = '';
                //     this.b3 = '';
                //     this.b4 = '';
                //     this.b5 = '';
                // } else if (v === 'b2') {
                //     this.b1 = '';
                //     this.b2 = 'div-class';
                //     this.b3 = '';
                //     this.b4 = '';
                //     this.b5 = '';
                // } else if (v === 'b3') {
                //     this.b1 = '';
                //     this.b2 = '';
                //     this.b3 = 'div-class';
                //     this.b4 = '';
                //     this.b5 = '';
                // } else if (v === 'b4') {
                //     this.b1 = '';
                //     this.b2 = '';
                //     this.b3 = '';
                //     this.b4 = 'div-class';
                //     this.b5 = '';
                // }
            },

            levelDh(v) {
                // alert('1')
                if (v === 'b1') {
                    this.b1 = 'dddd';
                } else if (v === 'b2') {
                    this.b2 = 'dddd';
                }
            }
        }
    }
</script>

<style scoped>
    .div-class {
        animation: dhtest 0.2s  ease-in-out;
        animation-fill-mode:forwards
    }

    .dddd {
        background-color: rgba(44, 75, 142, 0.8);
        width: 600px;
        transition: ease-in-out 600ms;
        /*animation: tttt 0.3s  ease-in-out;*/
        /*animation-fill-mode:forwards*/
    }

    @keyframes dhtest {
        100% {
            background-color: rgba(44, 75, 142, 0.8);
            width: 600px;
        }
    }

    @keyframes tttt {
        50% {
            width: 200px;
        }
    }

    .el-divider {
        height: 100% !important;
    }

    .div-card {
        width: 100%; height: 100%; padding: 20px; -webkit-transition: ease-in-out 2000ms; -webkit-transition-delay: 2s;
    }

    .div-date {
        font-size: 15px; margin-top: 10px; margin-bottom: 30px;
    }

    .div-title {
        font-size: 17px; width: calc(100% - 40px); line-height: 30px;
    }

    .div-content {
        font-size: 23px; margin-top: 10px; margin-bottom: 30px; width: calc(100% - 40px);
    }

    .div-big {
        height: 100%;width: 350px; background-color: rgba(44, 75, 142, 0.8);
    }

    .content {
        transition: ease-in-out 300ms;
    }

    .content:hover{
        width: 600px;
    }
    .content:hover > .overview {
        display: none;
    }

    .detail {
        /*display: none;*/
        opacity: 0;
        transition: ease-in-out 400ms;
        transition-delay: 0.3s;
        position: relative;
    }

    .content:hover > .detail {
        /*display: block;*/
        opacity: 1;
    }

    .detail-btn {
        position: absolute;
        bottom: 50px;
        right: 70px;
    }


</style>
